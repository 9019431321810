import {
    createRouter,
    createWebHistory
} from "vue-router";

import Dashboard from '@/pages/Dashboard.vue'
import LiveStream from '@/pages/LiveStream.vue'
import YoutubeStream from '@/pages/YoutubeStream.vue'
import Blank from '@/pages/Blank.vue'

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes: [{
        path: '/',
        name: 'dashboard',
        component: Dashboard
    }, {
        path: '/wood',
        name: 'liveStream',
        component: LiveStream
    }, {
        path: '/ys-watch',
        name: 'youtubeStream',
        component: YoutubeStream
    }, {
        path: '/blank',
        name: 'blank',
        component: Blank
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/dashboard'
    }]
})

export default router;