<template>
  <iframe
    v-if="url.length && channel && channel.youtubeId"
    id="ytplayer"
    type="text/html"
    width="100%"
    height="100vh"
    class="h-100"
    :src="url"
    frameborder="0"
  ></iframe>
</template>
<script>
export default {
  data() {
    return {
      url: "",
      channel: null,
    };
  },
  mounted() {
    const id = this.$route.query.ch;
    this.channel = this.$store.getters.getChannelById(id);
    document.title = this.channel.name;
    this.url = `https://www.youtube.com/embed/${this.channel.youtubeId}?autoplay=1`;
  },
};

// // Load the IFrame Player API code asynchronously.

//
</script>