<template>
  <div id="playerd"></div>
</template>
<script>
export default {
  name: "liveStream",
  data() {
    return {
      channel: null,
      id: "",
      source: "",
    };
  },
  mounted() {
    this.id = this.$route.query.ch;
    this.init();
  },
  methods: {
    init() {
      this.channel = this.$store.getters.getChannelById(this.id);
      document.title = this.channel.name;
      var playerElement = document.getElementById("playerd");

      var player = new window.Clappr.Player({
        source: this.channel.source,
        mimeType: "application/x-mpegURL",
        //mimeType: "application/vnd.apple.mpegurl",
        crossOrigin: "anonymous",
        width: "100%",
        height: "100vh",
        exitFullscreenOnEnd: false,
        autoPlay: true,
        playback: {
          playInline: true,
          recycleVideo: true 
        },
        plugins: [
          window.LevelSelector,
          window.ChromecastPlugin,
         
          //   window.ClapprPip.PipButton,
          //   window.ClapprPip.PipPlugin,
          //   window.DashShakaPlayback,
          //   window.Clappr.MediaControl,
          //   window.PlaybackRatePlugin,
        ],
        events: {
          onReady: function () {
            var plugin = this.getPlugin("click_to_pause");
            plugin && plugin.disable();
          },
          onError: function (e) {
            // Here the code to handle the error
            console.log(e);
          },
        },

        chromecast: {
          appId: "9DFB77C0",
          media: {
            title: "Awesome Hot Air Balloon Slackline",
            subtitle: "You won't get much closer to Skylining than this!",
          },
        },
        // chromecast: {
        //   appId: "9DFB77C0",
        //   contentType: "application/x-mpegURL",
        // },
      });
      player.attachTo(playerElement);
    },
  },
};
</script>
<style lang="scss">
.container {
  .player {
  }
}
.container #player > div {
  width: 100% !important;
}
</style>
 