import {
    createStore
} from 'vuex'

import channels from "./../assets/db/channels.json"
import categories from "./../assets/db/categories.json"
import countries from "./../assets/db/countries.json"
import tvGuides from "/public/assets/db/tvguide/estrellas.json"
import chSports from "/public/assets/db/ch-sports.json"

// Create a new store instance.
const store = createStore({
    state() {
        return {
            countries,
            channels,
            categories,
            tvGuides,
            chSports
        }
    },
    getters: {
        getFilteredChannels: (state) => (payload) => {
            return state.channels.filter(channel => channel.category === payload.category)
        },
        getSportsChannels: (state) => () => {
            return state.chSports
        },
        getFilteredYoutubeChannels: (state) => () => {
            return state.channels.filter(channel => channel.youtubeId && channel.youtubeId.length > 0)
        },
        getChannelById: (state) => (id) => {
            const channels = [...state.channels, ...state.chSports]
            return channels.find(channel => channel.id === id)
        },
        getTvGuideById: (state) => (id) => {
            return state.tvGuides.find(guide => guide.id === id)
        },
    },
    mutations: {

    },
    actions: {

    }
})

export default store;