<template>
  <div>Blank</div>
</template>
<script>
export default {
  name: "CBlank",
  mounted() {
    document.title = "Blank";
  },
};
</script>