<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      canal6: "",
    };
  },
  mounted() {
    document.title = "Live Stream";
    this.canal6 = window.atob(
      "aHR0cHM6Ly9jaGFubmVsMDFkYWktb25seW1leC5ha2FtYWl6ZWQubmV0L2hscy9saXZlLzIwMjI3NDkvZXZlbnQwMS9pbmRleC5tM3U4P2hkbmVhPXN0PTE2NDY1ODI1NjZ+ZXhwPTE2NDY1ODI4NjZ+YWNsPSp+aWQ9YjdkMTU1NDgtMzU0OC00Yzc4LWE0ZDQtMmMyZTc4YTVmODk4fmhtYWM9ODc0N2Y5MGM4YjhiZWM3Mzk3NjNjNGQ5M2M2OTlhYjlkNWYwZTdiODBjODk5MjE4OTA3ZmJlMGM4ZTkwMjJmOSZvcmlnaW5wYXRoPS9saW5lYXIvaGxzL3BhL2V2ZW50L19lMXNfVTUyU0NHTDZ6VG5sVHJiVlEvc3RyZWFtLzRlMWM3ZDM3LTYzMDEtNDRhMi1hMzVlLTQ4ZGI5NGYxZTQ0ZTpCUlUvbWFzdGVyLm0zdTg="
    );
  },
};
</script>

<style>
body,
html {
  height: 100%;
  overflow: hidden;
  background-color: #efefef;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
