<template>
  <div class="myIdentifier">
    <router-link class="card" target="_blank"  :to="{ path: '/wood', query: { ch: channel.id } }">
      <img :src="channel.logo">
    </router-link>
  </div>
</template>
      
<script>
import moment from "moment";
// import Swiper core and required modules
// import { Navigation, Pagination, A11y } from "swiper";

// Import Swiper Vue.js components
// import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";

export default {
  props: {
    channel: {
      type: Object,
      default: null,
    },
  },
  components: {
    // Swiper,
    // SwiperSlide,
  },
  data() {
    return {
      cdate: {
        start: 0,
        end: 0,
        percent: 20,
        now: 0,
      },
      test: 0,
      tvGuide: null,
      isYoutube: false,
      height: 110
    };
  },
  // setup() {
  //   const onSwiper = (swiper) => {
  //     console.log(swiper);
  //   };
  //   const onSlideChange = () => {
  //     console.log("slide change");
  //   };
  //   return {
  //     onSwiper,
  //     onSlideChange,
  //     modules: [Navigation, Pagination, A11y],
  //   };
  // },
  computed() { },
  mounted() {
    const startDate = "2022-03-21 12:00:00";
    const today = moment().format("YYYY-MM-DD HH:mm:ss");
    const endDate = "2022-03-21 13:00:00";

    this.cdate.start = moment(startDate, "YYYY-MM-DD HH:mm:ss").unix();
    this.cdate.now = moment(today, "YYYY-MM-DD HH:mm:ss").unix();
    this.cdate.end = moment(endDate, "YYYY-MM-DD HH:mm:ss").unix();

    this.cdate.percent =
      ((this.cdate.now - this.cdate.start) * 100) /
      (this.cdate.end - this.cdate.start);

    // this.tvGuide = this.$store.getters.getTvGuideById(this.channel.id);
    // console.log(this.tvGuide);
    // if (this.category !== "youtube") {
    //   this.channels = this.$store.getters.getFilteredChannels({
    //     category: this.category,
    //   });
    // } else {
    //   this.channels = this.$store.getters.getFilteredYoutubeChannels();
    // }
  },
};
</script>
      
<style lang="scss" scoped>
.card {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #323232;
  border-radius: 10px;
  color: #cccc;
  width: 100%;
  min-height: 78px;
  padding: 10px;
  cursor: pointer;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .card {
    min-height: 120px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .card {
    min-height: 85px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .card {
    min-height: 90px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .card {
    min-height: 95px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .card {
    min-height: 110px;
  }

}
</style>