<template>
  <div>
    <header>
      <div class="brand">
        JMCCTV
      </div>
      <div class="menu">
        <button mat-button class="mx-2">SPORTS</button>
        <button mat-button class="mx-2">NEWS</button>
        <button mat-button class="mx-2">OTHERS</button>
        <button mat-button class="mx-2" v-on:click="corstest">TEST - CORS</button>
      </div>
    </header>

    <main>
      <div
        class="grid gap-2 grid-cols-4 sm:grid-cols-6 lg:gap-3 md:grid-cols-8 lg:grid-cols-10 xl:grid-cols-12 2xl:grid-cols-14 p-3">
        <channel-items v-for="(item, index) in channels" :key="index" :channel="item"></channel-items>
      </div>
    </main>

  </div>
</template>
<script>
import ChannelItems from "./../components/ChannelItems.vue";

export default {
  name: "VDashboard",
  components: {
    ChannelItems,
  },

  data() {
    return {
      countries: [],
      categories: [],
      channels: [],
      sportChannels: [],
      category: "news",
    };
  },
  methods: {
    corstest() {
      fetch("http://localhost:8080/api/values/corstest", {
        method: "GET", // or 'PUT'
        
      }).then((result) => {
        console.log(result);
      })
    }
  },
  mounted() {
    document.title = "Live Stream";
    // methods can be called in lifecycle hooks, or other methods!
    this.countries = this.$store.state.countries || [];
    this.categories = this.$store.state.categories || [];
    this.channels = this.$store.getters.getFilteredChannels({
      category: this.category,
    });
    this.sportChannels = this.$store.getters.getSportsChannels();
  },
};
</script>
 

<style scoped lang="scss"></style>